var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer__wrapper" }, [
      _c(
        "div",
        { staticClass: "footer__group" },
        _vm._l(_vm.catalogMenu, function(item, i) {
          return _c(
            "a",
            {
              key: "category-" + i,
              staticClass: "footer__item footer__item-section",
              attrs: { href: item.link }
            },
            [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "footer__group" }, [
        _c(
          "div",
          {
            staticClass: "footer__item footer__item-external-resources__markets"
          },
          [
            _c(
              "div",
              {
                staticClass: "footer__item-section footer__item-section--mb-20"
              },
              [_vm._v("ДЛЯ МОБИЛЬНЫХ УСТРОЙСТВ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__mobile",
                attrs: {
                  href: "https://itunes.apple.com/ru/app/ockarik/id871836087",
                  target: "_blank"
                }
              },
              [
                _c("img", {
                  staticClass: "footer__mobile__img",
                  attrs: {
                    src: _vm._f("cdn")(
                      "/skins/default/en/images/apple-store-link.svg"
                    ),
                    alt: "app-store-link"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__mobile",
                attrs: {
                  href:
                    "https://play.google.com/store/apps/details?id=com.ochkarikrn",
                  target: "_blank"
                }
              },
              [
                _c("img", {
                  staticClass: "footer__mobile__img",
                  attrs: {
                    src: _vm._f("cdn")(
                      "/skins/default/en/images/google-play-link.svg"
                    ),
                    alt: "google-play-link"
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer__item footer__item-external-resources" },
          [
            _c(
              "div",
              {
                staticClass: "footer__item-section footer__item-section--mb-20"
              },
              [_vm._v("МЫ В СОЦИАЛЬНЫХ СЕТЯХ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "footer__network analyticsSendGoogleEventPerehodVK",
                attrs: { href: "http://vk.com/club19204041", target: "_blank" }
              },
              [
                _c("img", {
                  staticClass: "footer__network__img",
                  attrs: {
                    src: _vm._f("cdn")("/skins/default/en/images/vk.svg"),
                    alt: "vk-link"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "footer__network__link" }, [
                  _vm._v("Вконтакте")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__network",
                attrs: { href: "https://t.me/ochkarikoptica", target: "_blank" }
              },
              [
                _c("img", {
                  staticClass: "footer__network__img",
                  attrs: {
                    src: _vm._f("cdn")("/skins/default/en/images/tgk.svg"),
                    alt: "tgk-link"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "footer__network__link" }, [
                  _vm._v("Телеграм")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__network",
                attrs: {
                  href: "https://zen.yandex.ru/id/61b9b45479d8b44cbf72c628",
                  target: "_blank"
                }
              },
              [
                _c("span", [
                  _c("img", {
                    staticClass: "footer__network__img",
                    attrs: {
                      src: _vm._f("cdn")("/skins/default/en/images/zen.svg"),
                      alt: "zen-link"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "footer__network__link" }, [
                  _vm._v("Яндекс.Дзен")
                ])
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__group" }, [
      _c(
        "a",
        {
          staticClass: "footer__item footer__item-section",
          attrs: { href: "/e-shop/" }
        },
        [_vm._v("ИНТЕРНЕТ-МАГАЗИН")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/profile/" } }, [
        _vm._v("Личный кабинет")
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/punkty-vidachy/" } },
        [_vm._v("Пункты выдачи заказов")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/oplata/" } }, [
        _vm._v("Оплата")
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/e-shop-delivery/" } },
        [_vm._v("Доставка")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/obmen-i-vozvrat/" } },
        [_vm._v("Обмен и возврат")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/oformlenie-zakaza/" } },
        [_vm._v("Оформление заказа")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footer__item",
          attrs: { href: "/personalnye-dannye/" }
        },
        [_vm._v(" Персональные данные")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/public-oferta/" } },
        [_vm._v("Публичный договор-оферта")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/sertifikat/" } },
        [_vm._v("Регистрационные удостоверения и сертификаты на продукцию")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/faq/" } }, [
        _vm._v("FAQ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__group" }, [
      _c(
        "a",
        {
          staticClass: "footer__item footer__item-section",
          attrs: { href: "/salony-optiki/" }
        },
        [_vm._v("САЛОНЫ ОПТИКИ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/news-rejim/" } },
        [_vm._v("Изменения режима работы салонов")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/promotion-events/" } },
        [_vm._v("Акции")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/special-offers/" } },
        [_vm._v("Наши преимущества")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/experts/" } }, [
        _vm._v("Наши специалисты")
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/medical-services/" } },
        [_vm._v("Медицинские услуги")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/public-offer/" } },
        [_vm._v("Публичный договор")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/tovari-uslugi/" } },
        [_vm._v("Товары и услуги")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/consumer-corner/" } },
        [_vm._v("Уголок потребителя ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/page-order-status/" } },
        [_vm._v("Статус заказа")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/customers-service/" } },
        [_vm._v("Сервис")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/arendodateliam/" } },
        [_vm._v("Арендодателям")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__group" }, [
      _c(
        "a",
        {
          staticClass: "footer__item footer__item-section",
          attrs: { href: "/about/" }
        },
        [_vm._v("О КОМПАНИИ")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/news/" } }, [
        _vm._v("Новости")
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "footer__item", attrs: { href: "/brend-ochkarik-new" } },
        [_vm._v("Бренд «Очкарик»")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/history/" } }, [
        _vm._v("История сети")
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/our-team/" } }, [
        _vm._v("Обучение и развитие персонала")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footer__item",
          attrs: { href: "/nauchno-proizvodstvennaya-baza-new" }
        },
        [_vm._v("Научно-производственная база")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footer__item",
          attrs: { href: "/best-worlds-dealers/" }
        },
        [_vm._v("Наши поставщики")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/caryera/" } }, [
        _vm._v("Карьера")
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/vacancies/" } }, [
        _vm._v("Вакансии")
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "footer__item", attrs: { href: "/contact/" } }, [
        _vm._v("Контакты")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }